"use client";
import React from "react";
import styles from "@aspen/theme/saas/login.module.less";
import { Login } from "@saas/widgets";
import { useSaasTenant } from "@aspen/hooks";
import { i18nUtil } from "@aspen/libs";

export const PageLogin: React.FC = () => {
  const intl = i18nUtil.t();
  const tenant = useSaasTenant();
  return (
    <section className={styles.container}>
      <div className={styles.loginContainer}>
        <div
          className={styles.leftBox}
          style={{
            backgroundImage: `url('${tenant?.backgroundUrl ?? ""}')`
          }}>
          {tenant?.companyShortName ? (
            <div className={styles.loginTitleText}>
              {intl["saas.welcome"]}
              <div>{tenant?.companyShortName ?? ""}</div>
            </div>
          ) : null}
        </div>
        <Login />
      </div>
    </section>
  );
};

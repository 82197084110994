"use client";
import React, { useEffect, useMemo, useState } from "react";
import styles from "@aspen/theme/saas/wallet.module.less";
import { Spin, Table, Tabs } from "antd";
import { useRouter } from "next/router";
import {
  decimalPointNoMoreXNoFill,
  i18nUtil,
  numberToThousands,
  BENCHMARK_CURRENCY_SYMBOL,
  REFERENCE_CURRENCY,
  SAAS,
  SAAS_COMMON_CACHE_PRIORITY_KEYS,
  SAAS_INVESTMENT_TAB,
  SAAS_VENTURE_DETAIL_TAB,
  strategy_wallet_column,
  strategy_wallet_dataIndex,
  venture_wallet_column,
  venture_wallet_dataIndex,
  isClient,
  SASS_PERMISSION_KEY,
  computeNumber,
  getGradientColor
} from "@aspen/libs";
import clsx from "clsx";
import { isNil } from "lodash-es";
import { PieChart } from "@saas/ui";
import { ActionColumn } from "@saas/widgets";
import { useSaasCachePriorityData } from "@aspen/hooks";
import type { InformationUnreadVO } from "@aspen/model";
import { getSaasUnreadInvestorPortal } from "@aspen/services";
import type { TabsProps } from "antd";

const getTextInfo = (value) => {
  if (Number(value) < 0) {
    return { symbol: "", textClass: styles.fallText };
  }
  if (Number(value) > 0) {
    return { symbol: "+", textClass: styles.riseText };
  }
  return { symbol: "", textClass: styles.riseText };
};

interface IProps {
  colors: {
    chart1Bg1: string;
    chart1Bg2: string;
    chart2Bg1: string;
    chart2Bg2: string;
  };
}

export const PageWallet: React.FC<IProps> = (props: IProps) => {
  const { colors } = props;
  const productColor1 = getGradientColor(colors.chart1Bg1, colors.chart1Bg2);
  const productColor2 = getGradientColor(colors.chart2Bg1, colors.chart2Bg2);
  const intl = i18nUtil.t();
  const router = useRouter();
  const saasPermission =
    (isClient && JSON.parse(localStorage.getItem(SASS_PERMISSION_KEY) ?? "{}")) || {};

  const { showStrategyModule, showVentureModule } = saasPermission;

  const tabItems = [
    showVentureModule
      ? {
          label: intl["saas.venture"],
          key: SAAS_INVESTMENT_TAB.venture
        }
      : null,
    showStrategyModule
      ? {
          label: intl["saas.strategy"],
          key: SAAS_INVESTMENT_TAB.strategy
        }
      : null
  ].filter(Boolean) as TabsProps["items"];

  const [currentTab, setCurrentTab] = useState(SAAS_INVESTMENT_TAB.venture);
  const [data, setData] = useState<any>({
    aum: 0,
    pnl: 0,
    allocationList: [],
    venturePositionList: [],
    strategyPositionList: []
  });
  const [ventureUnreadCount, setVentureUnreadCount] = useState<InformationUnreadVO[]>([]);

  useEffect(() => {
    const defaultTab = router?.query?.currentTab?.toString() ?? SAAS_INVESTMENT_TAB.venture;
    setCurrentTab(defaultTab);
  }, [router?.query?.currentTab]);

  const fetchUnread = () => {
    getSaasUnreadInvestorPortal({
      updatesProductType: "VENTURE"
    }).then((res) => {
      if (res?.code == "0") {
        setVentureUnreadCount(res?.data ?? []);
      }
    });
  };

  useEffect(() => {
    fetchUnread();
  }, []);

  const handleChangeTabs = (value) => {
    setCurrentTab(value);
    router?.replace({ query: { currentTab: value } });
  };
  const { data: walletInfo, isLoading }: any = useSaasCachePriorityData<any>({
    key: SAAS_COMMON_CACHE_PRIORITY_KEYS.saasWalletInfo,
    params: {
      allocationType: showStrategyModule ? 0 : 1
    }
  });
  useEffect(() => {
    if (walletInfo) {
      setData(walletInfo);
    }
  }, [walletInfo]);

  const handleToVentureUpdate = (productId: string) => {
    if (!productId) return;
    router.push({
      pathname: `${SAAS.VENTURES_DETAIL}/${productId}`,
      query: {
        currentTab: SAAS_VENTURE_DETAIL_TAB.update
      }
    });
  };

  const columns = useMemo(() => {
    let columnData;
    let dataIndex;
    let width;
    switch (currentTab) {
      case SAAS_INVESTMENT_TAB.venture:
        columnData = venture_wallet_column;
        dataIndex = venture_wallet_dataIndex;
        break;
      case SAAS_INVESTMENT_TAB.strategy:
        columnData = strategy_wallet_column;
        dataIndex = strategy_wallet_dataIndex;
        break;
      default:
        break;
    }
    return (columnData ?? []).map((co) => {
      let render;
      let className;
      let align = "left";
      if (["venture", "strategy"].includes(co)) {
        className = styles.productTd;
        render = (value, record) => {
          const findUnread = ventureUnreadCount.find(
            (item) => item.productId === record?.ventureId
          );
          return (
            <div className={styles.ventureProductBox}>
              <div title={value} className={styles.productName}>
                {value}
              </div>
              {findUnread && findUnread?.unreadCount !== 0 ? (
                <div
                  onClick={() => handleToVentureUpdate(record?.ventureId)}
                  className={styles.ventureUpdatesCount}>
                  {findUnread?.unreadCount}
                </div>
              ) : null}
            </div>
          );
        };
      }
      if (co === "changeAmount") {
        align = "right";
        render = (value) => {
          const textInfo = getTextInfo(Number(value ?? 0));
          return (
            <span className={textInfo.textClass}>
              {textInfo.symbol}
              {isNil(value) ? "--" : numberToThousands(decimalPointNoMoreXNoFill(value, 2))}
            </span>
          );
        };
      }
      if (co === "fairValue") {
        align = "right";
        render = (value) =>
          isNil(value)
            ? "--"
            : `${BENCHMARK_CURRENCY_SYMBOL}${numberToThousands(
                decimalPointNoMoreXNoFill(value, 2)
              )}`;
      }
      if (co === "changePercent") {
        align = "right";
        render = (value) => {
          const textInfo = getTextInfo(Number(value ?? 0));
          return (
            <span className={textInfo.textClass}>
              {textInfo.symbol}
              {isNil(value) ? "--" : decimalPointNoMoreXNoFill(value, 2)}%
            </span>
          );
        };
      }
      if (co === "type") {
        render = (value) => intl[`saas.strategy.type.${value}`];
      }
      if (co === "stage") {
        render = (value) => intl[`saas.venture.shares.${value}`];
      }
      if (co === "control") {
        width = 330;
        align = "right";
        render = (value, record) => {
          return (
            <ActionColumn
              type={currentTab}
              data={{
                ...record,
                id:
                  currentTab === SAAS_INVESTMENT_TAB.venture ? record.ventureId : record.strategyId,
                name:
                  currentTab === SAAS_INVESTMENT_TAB.venture
                    ? record.ventureName
                    : record.strategyName
              }}
            />
          );
        };
      }
      return {
        title: intl[`saas.column.${co}`],
        dataIndex: dataIndex?.[co] ?? co,
        width,
        render,
        align,
        className
      };
    });
  }, [currentTab, ventureUnreadCount]);

  const pnlInfo = useMemo(() => {
    return getTextInfo(Number(data?.pnl));
  }, [data?.pnl]);

  const dataSource = useMemo(() => {
    if (currentTab === SAAS_INVESTMENT_TAB.venture) {
      return data.venturePositionList;
    }
    return data.strategyPositionList;
  }, [currentTab, data]);
  const pieData = useMemo(() => {
    const pie = (data.allocationList ?? []).map((item) => {
      return {
        value: item.amount,
        percent: `${computeNumber(item.percent, "*", 100).result}%`,
        name: intl[`saas.${item.type}`]
      };
    });
    // venture在前，strategy在后
    return pie.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      }
      return 1;
    });
  }, [data.allocationList]);
  return (
    <section className="saasCustomContainer">
      <p className={styles.headerText}>{intl["saas.wallet"]}</p>
      <Spin spinning={isLoading}>
        <div className={styles.headerBox}>
          <div className={styles.itemCardBox}>
            <div className={styles.cardHeader}>
              {intl["saas.wallet.aum"]}
              &nbsp;
              <span className={styles.cardHeaderUnit}>({REFERENCE_CURRENCY})</span>
            </div>
            <div className={styles.cardContent}>
              {BENCHMARK_CURRENCY_SYMBOL}
              {numberToThousands(decimalPointNoMoreXNoFill(data?.aum, 2))}
            </div>
          </div>
          <div className={clsx(styles.itemCardBox, styles.cardMargin)}>
            <div className={styles.cardHeader}>
              {intl["saas.wallet.pnl"]}
              &nbsp;
              <span className={styles.cardHeaderUnit}>({REFERENCE_CURRENCY})</span>
            </div>
            <div className={clsx(styles.cardContent, pnlInfo.textClass)}>
              {pnlInfo.symbol}
              {BENCHMARK_CURRENCY_SYMBOL}
              {numberToThousands(decimalPointNoMoreXNoFill(data?.pnl, 2))}
            </div>
          </div>
          <div className={styles.itemCardBox}>
            <div className={styles.cardHeader}>{intl["saas.wallet.allocation"]}</div>
            <div className={styles.cardPieContent}>
              <PieChart colors={[productColor1, productColor2]} data={pieData} />
            </div>
          </div>
        </div>
        <div className={styles.contentBox}>
          <Tabs
            items={tabItems}
            className={styles.tab}
            activeKey={currentTab}
            onChange={handleChangeTabs}
          />
          <div className={styles.walletTableList}>
            <Table
              columns={columns}
              dataSource={dataSource}
              rowKey={(record: any) =>
                currentTab === SAAS_INVESTMENT_TAB.venture ? record?.ventureId : record?.strategyId
              }
              pagination={false}
            />
          </div>
        </div>
      </Spin>
    </section>
  );
};

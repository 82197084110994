"use client";
import React, { useState } from "react";
import styles from "@aspen/theme/saas/contact.module.less";
import { i18nUtil, reportEvent, SAAS_GA_EVENT_NAME } from "@aspen/libs";
import { Input } from "antd";

import { CommonButton, CommonMailto } from "@saas/ui";
import { useSaasTenant } from "@aspen/hooks";
import { submitContactInfo } from "@aspen/services";
import { ModalContactSuccess } from "@saas/widgets";
import { message } from "@aspen/ui";

export const PageContact: React.FC = () => {
  const intl = i18nUtil.t();
  const tenant = useSaasTenant();
  const supportEmail = tenant?.supportEmail;
  const _subject = `【${tenant?.companyShortName || ""}】General Enquiry`;
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);

  const handleClick = () => {
    if (value) {
      setLoading(true);
      reportEvent({
        moduleName: SAAS_GA_EVENT_NAME.contact.submitInfo,
        detailParams: { supportEmail }
      });
      submitContactInfo({ contactInfo: value })
        .then((res) => {
          if (res?.code == "0") {
            setVisibleSuccess(true);
            setValue("");
          } else {
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .catch((err) => {
          message.error(intl?.[err?.msg] ?? err?.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const replyGA = () => {
    reportEvent({
      moduleName: SAAS_GA_EVENT_NAME.contact.replyEmail,
      detailParams: { mailto: supportEmail }
    });
  };

  const descs = intl["saas.contact.desc"];
  return (
    <section className="saasCustomContainer">
      <p className={styles.headerText}>{intl["saas.contact"]}</p>
      <div className={styles.contentBox}>
        <div className={styles.desc}>
          <div>{descs[0]}</div>
          <div>{descs[1]}</div>
          <div className={styles.supportEmail} onClick={replyGA}>
            <CommonMailto
              mailto={supportEmail}
              btnContent={supportEmail}
              subject={_subject}
              className={styles.commonMailtoText}
            />
          </div>
          <div>{descs[2]}</div>
        </div>
        <div className={styles.message}>{intl["saas.your.message"]}</div>
        <Input.TextArea
          className={styles.textArea}
          value={value}
          rows={6}
          placeholder={intl["saas.mailto.plc"]}
          onChange={handleChange}
          maxLength={2000}
        />
        <CommonButton
          loading={loading}
          onClick={handleClick}
          disabled={!value}
          className={styles.submitButton}
          btntype="solid">
          {intl["saas.button.submit"]}
        </CommonButton>
      </div>
      {visibleSuccess && (
        <ModalContactSuccess
          visible={visibleSuccess}
          cancelModal={() => {
            setVisibleSuccess(false);
          }}
        />
      )}
    </section>
  );
};

"use client";
import React, { useEffect, useMemo, useState } from "react";
import styles from "@aspen/theme/saas/venture.module.less";
import { Spin, Tabs } from "antd";
import { useRouter } from "next/router";
import {
  i18nUtil,
  reportEvent,
  GA_EVENT_TAG,
  SAAS_COMMON_CACHE_PRIORITY_KEYS,
  SAAS_DEFAULT_LIMIT,
  SAAS_GA_EVENT_NAME,
  SAAS_INIT_PAGE_INFO,
  SAAS_VENTURE_SHARES,
  SAAS_VENTURE_TAB
} from "@aspen/libs";
import { ProductItem } from "@saas/widgets";
import clsx from "clsx";
import { getSaasInterestVentureList, getSaasVentureList } from "@aspen/services";
import { AsPagination, message } from "@aspen/ui";
import type { IPagerInfo, IReqSaasVentureList, IResSaasVenture } from "@aspen/model";
import { useSaasCachePriorityData, useSaasTenant } from "@aspen/hooks";

export const PageVentures: React.FC = () => {
  const intl = i18nUtil.t();
  const tenant = useSaasTenant();
  const router = useRouter();
  const tabItems = [
    {
      label: intl["saas.ventures.deal"],
      key: SAAS_VENTURE_TAB.deal
    },
    {
      label: intl["saas.ventures.fund"],
      key: SAAS_VENTURE_TAB.fund
    },
    {
      label: intl["saas.button.favorite"],
      key: SAAS_VENTURE_TAB.favorites
    }
  ];
  const [currentTab, setCurrentTab] = useState("");
  const [ventures, setVentures] = useState<IResSaasVenture[]>([]);
  const [pagerInfo, setPagerInfo] = useState<IPagerInfo>(SAAS_INIT_PAGE_INFO);
  const [isLoading, setIsLoading] = useState(true);

  const { data: interestVentureIds }: any = useSaasCachePriorityData<any>({
    key: SAAS_COMMON_CACHE_PRIORITY_KEYS.saasInterestVentures
  });

  useEffect(() => {
    const defaultTab = router?.query?.currentTab?.toString() ?? SAAS_VENTURE_TAB.deal;
    setCurrentTab(defaultTab);
  }, [router?.query?.currentTab]);

  const handleChangeTabs = (value) => {
    reportEvent({
      moduleName: SAAS_GA_EVENT_NAME.product.changeTab,
      joinedTag: GA_EVENT_TAG.Click,
      detailParams: {
        tab: value,
        type: "venture"
      }
    });
    setCurrentTab(value);
    router?.replace({ query: { currentTab: value } });
  };
  const method = useMemo(() => {
    if (currentTab === SAAS_VENTURE_TAB.favorites) {
      return getSaasInterestVentureList;
    }
    return getSaasVentureList;
  }, [currentTab]);

  const fetchVentures = (_pagerInfo: IPagerInfo) => {
    setIsLoading(true);
    const params: IReqSaasVentureList = {
      nextToken: _pagerInfo.nextToken,
      limit: SAAS_DEFAULT_LIMIT
    };
    if ([SAAS_VENTURE_TAB.deal, SAAS_VENTURE_TAB.fund].includes(currentTab)) {
      params.productType =
        currentTab === SAAS_VENTURE_TAB.deal ? SAAS_VENTURE_SHARES.DEAL : SAAS_VENTURE_SHARES.FUND;
    }
    method(params)
      .then((res) => {
        if (res?.code == "0") {
          const isLastData =
            res?.data?.rows?.length === SAAS_DEFAULT_LIMIT &&
            res?.data?.totalCount === _pagerInfo.page * SAAS_DEFAULT_LIMIT;
          setPagerInfo({
            page: _pagerInfo.page,
            nextToken: isLastData ? "" : (res?.data?.nextToken ?? "")
          });
          setVentures(res?.data?.rows ?? []);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (currentTab !== "") {
      fetchVentures(SAAS_INIT_PAGE_INFO);
    }
  }, [currentTab]);

  return (
    <section className="saasCustomContainer">
      <p className={styles.headerText}>{intl["saas.venture"]}</p>
      <div className={styles.contentBox}>
        <Tabs
          items={tabItems}
          className={styles.tab}
          activeKey={currentTab}
          onChange={handleChangeTabs}
        />
        <Spin spinning={isLoading}>
          <div className={styles.ventureList}>
            {ventures.length === 0 ? (
              <div className={styles.noData}>{intl["saas.noData"]}</div>
            ) : (
              ventures.map((item) => (
                <div key={item.id} className={styles.ventureListItem}>
                  <ProductItem
                    item={item}
                    productType="venture"
                    interestVentureIds={interestVentureIds}
                  />
                </div>
              ))
            )}
          </div>
        </Spin>
        {ventures.length > 0 && (
          <AsPagination
            pagerInfo={pagerInfo}
            onPageChange={(_pagerInfo) => {
              fetchVentures(_pagerInfo);
            }}
          />
        )}
      </div>
    </section>
  );
};

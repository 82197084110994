"use client";
import React, { useEffect, useState } from "react";
import styles from "@aspen/theme/saas/investor.module.less";
import {
  i18nUtil,
  reportEvent,
  GA_EVENT_TAG,
  SAAS_GA_EVENT_NAME,
  SAAS_INVESTOR_TAB
} from "@aspen/libs";
import { Tabs } from "antd";
import { useRouter } from "next/router";
import { Updates, News, Research, Expert } from "@saas/widgets";

export const PageInvestor: React.FC = () => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const tabItems = [
    {
      label: intl["saas.updates"],
      key: SAAS_INVESTOR_TAB.updates
    },
    {
      label: intl["saas.news"],
      key: SAAS_INVESTOR_TAB.news
    },
    {
      label: intl["saas.research"],
      key: SAAS_INVESTOR_TAB.research
    },
    {
      label: intl["saas.expert"],
      key: SAAS_INVESTOR_TAB.expert
    }
  ];
  const [currentTab, setCurrentTab] = useState(SAAS_INVESTOR_TAB.updates);

  useEffect(() => {
    const defaultTab = router?.query?.currentTab?.toString() ?? SAAS_INVESTOR_TAB.updates;
    setCurrentTab(defaultTab);
  }, [router?.query?.currentTab]);

  const handleChangeTabs = (value: keyof typeof SAAS_INVESTOR_TAB) => {
    reportEvent({
      moduleName: SAAS_GA_EVENT_NAME.investor.changeTab,
      joinedTag: GA_EVENT_TAG.Click,
      detailParams: {
        tab: value,
        type: "investor"
      }
    });
    setCurrentTab(value);
    router?.replace({ query: { currentTab: value } });
  };

  return (
    <section className="saasCustomContainer">
      <p className={styles.headerText}>{intl["saas.investor"]}</p>
      <div className={styles.contentBox}>
        <Tabs
          items={tabItems}
          className={styles.tab}
          activeKey={currentTab}
          onChange={handleChangeTabs}
        />
        <div className={styles.tabContentBox}>
          {currentTab === SAAS_INVESTOR_TAB.updates && <Updates />}
          {currentTab === SAAS_INVESTOR_TAB.news && <News />}
          {currentTab === SAAS_INVESTOR_TAB.research && <Research />}
          {currentTab === SAAS_INVESTOR_TAB.expert && <Expert />}
        </div>
      </div>
    </section>
  );
};

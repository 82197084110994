"use client";
import React, { useEffect, useMemo, useState } from "react";
import styles from "@aspen/theme/saas/investment.module.less";
import {
  decimalPointNoMoreXNoFill,
  i18nUtil,
  numberToThousands,
  transformDate,
  BENCHMARK_CURRENCY_SYMBOL,
  SAAS_DEFAULT_TABLE_LIMIT,
  SAAS_INIT_PAGE_INFO,
  SAAS_INVESTMENT_TAB,
  strategy_investment_column,
  strategy_investment_dataIndex,
  venture_investment_column,
  venture_investment_dataIndex,
  SASS_PERMISSION_KEY,
  isClient
} from "@aspen/libs";
import { message, Spin, Table, Tabs } from "antd";
import type { TabsProps } from "antd";
import { useRouter } from "next/router";
import { getSaasStrategyInvestmentList, getSaasVentureInvestmentList } from "@aspen/services";
import { AsPagination } from "@aspen/ui";
import type { IPagerInfo, IResSaasVenture } from "@aspen/model";
import { isNil } from "lodash-es";

export const PageInvestment: React.FC = () => {
  const intl = i18nUtil.t();
  const router = useRouter();

  const saasPermission =
    (isClient && JSON.parse(localStorage.getItem(SASS_PERMISSION_KEY) ?? "{}")) || {};

  const { showStrategyModule, showVentureModule } = saasPermission;

  const tabItems = [
    showVentureModule
      ? {
          label: intl["saas.venture"],
          key: SAAS_INVESTMENT_TAB.venture
        }
      : null,
    showStrategyModule
      ? {
          label: intl["saas.strategy"],
          key: SAAS_INVESTMENT_TAB.strategy
        }
      : null
  ].filter(Boolean) as TabsProps["items"];

  const [currentTab, setCurrentTab] = useState<string>();
  const [data, setData] = useState([]);
  const [pagerInfo, setPagerInfo] = useState<IPagerInfo>(SAAS_INIT_PAGE_INFO);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (router.isReady) {
      const defaultTab = router?.query?.currentTab?.toString() ?? SAAS_INVESTMENT_TAB.venture;
      setCurrentTab(defaultTab);
    }
  }, [router?.query?.currentTab]);

  const method = useMemo(() => {
    switch (currentTab) {
      case SAAS_INVESTMENT_TAB.strategy:
        return getSaasStrategyInvestmentList;
      default:
        return getSaasVentureInvestmentList;
    }
  }, [currentTab]);

  const handleChangeTabs = (value) => {
    setCurrentTab(value);
    router?.replace({ query: { currentTab: value } });
  };

  const fetchInvestment = (_pagerInfo: IPagerInfo) => {
    setIsLoading(true);
    method({
      nextToken: _pagerInfo.nextToken,
      limit: SAAS_DEFAULT_TABLE_LIMIT
    })
      .then((res) => {
        if (res?.code == "0") {
          const isLastData =
            res?.data?.rows?.length === SAAS_DEFAULT_TABLE_LIMIT &&
            res?.data?.totalCount === _pagerInfo.page * SAAS_DEFAULT_TABLE_LIMIT;
          setPagerInfo({
            page: _pagerInfo.page,
            nextToken: isLastData ? "" : (res?.data?.nextToken ?? "")
          });
          setData(res?.data?.rows ?? []);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!currentTab) return;
    fetchInvestment(SAAS_INIT_PAGE_INFO);
  }, [currentTab]);

  const columns = useMemo(() => {
    let columnData;
    let dataIndex;
    switch (currentTab) {
      case SAAS_INVESTMENT_TAB.venture:
        columnData = venture_investment_column;
        dataIndex = venture_investment_dataIndex;
        break;
      case SAAS_INVESTMENT_TAB.strategy:
        columnData = strategy_investment_column;
        dataIndex = strategy_investment_dataIndex;
        break;
      default:
        break;
    }
    return (columnData ?? []).map((columnItem) => {
      let render;
      let className;
      let align = "left";
      if (columnItem === "date") {
        className = styles.dateTd;
        render = (value) => (value ? transformDate(value) : "--");
      }
      if (columnItem === "type") {
        render = (value) => intl[`saas.venture.type.${value}`];
      }
      if (columnItem === "stage") {
        render = (value) => intl[`saas.venture.shares.${value}`];
      }
      if (columnItem === "amount") {
        align = "right";
        render = (value) =>
          isNil(value)
            ? "--"
            : `${BENCHMARK_CURRENCY_SYMBOL}${numberToThousands(
                decimalPointNoMoreXNoFill(value, 2)
              )}`;
      }
      return {
        title: intl[`saas.column.${columnItem}`],
        dataIndex: dataIndex?.[columnItem] ?? columnItem,
        render,
        align,
        className
      };
    });
  }, [currentTab]);

  return (
    <section className="saasCustomContainer">
      <p className={styles.headerText}>{intl["saas.investment"]}</p>
      <div className={styles.contentBox}>
        <Tabs
          items={tabItems}
          className={styles.tab}
          activeKey={currentTab}
          onChange={handleChangeTabs}
        />
        <Spin spinning={isLoading}>
          <div className={styles.investmentList}>
            <Table
              columns={columns}
              dataSource={data}
              rowKey={(record: IResSaasVenture) => record?.id}
              pagination={false}
            />
            {data.length > 0 && (
              <AsPagination
                pagerInfo={pagerInfo}
                onPageChange={(_pagerInfo) => {
                  fetchInvestment(_pagerInfo);
                }}
              />
            )}
          </div>
        </Spin>
      </div>
    </section>
  );
};
